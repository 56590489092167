@font-face {
  font-family: Monserrat;
  src: url(fonts/Montserrat-Regular.ttf);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Monserrat;
}

.App {
  display: flex;
  flex: 1;
  margin: 0;
  background-color: '#fff';
}
