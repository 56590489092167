.tile {
  justify-content: center;
  align-items: center;
  border-radius: 70px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  outline: 0;
  border: 0;
}

.tile p {
  color: #fff;
  font-size: 40px;
}

.tile .svg-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.tile .svg-container svg {
  max-width: 300px;
}
